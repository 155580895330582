import React from 'react';
import styled from 'styled-components';
import { toPounds } from './../../services/Formats';

const LossGainWrapper = styled.div`
    max-width: 885px;
    margin: 0px auto;

    .zero { color: #666; }
    .minus { color: #ff1100; }
    .plus { color: #01860a; }
`;

export const LossGain: React.FC<any> = (props: any) => { 
    if (!props.value) return(
        <LossGainWrapper><div className='zero'>Breakeven</div></LossGainWrapper>
    );
    const cls = (props.value > 0) ? 'plus' : 'minus';
    const prefix = (props.value > 0) ? 'Gain' : 'Loss';
    return ( 
        <LossGainWrapper>
            <div className={cls}>{prefix} of {toPounds(Math.abs(props.value))}</div>
        </LossGainWrapper>
    );
} 
