export const toCurrency = (x: any) => {
  if (typeof x === 'undefined') return 0;
  if (isNaN(x)) return 0;
  const val = parseFloat(x).toFixed(0).toString()
      .replace(/\.0+$/, "")
      .replace(/([a-zA-Z]|,)/g, '');
  if (!isNaN(parseFloat(val))) {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  if (val === "") return 0;
  return val;
};

export const toPounds = (x: any) => `£${toCurrency(x)}`;

export const roundedTo500 = (x: any) => (Math.floor(x / 500) * 500);
export const roundedTo = (x: any, val: number) => (Math.floor(x / val) * val);
