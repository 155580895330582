import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Graph, Legend } from './Chart';
import { LossGain } from './Elements/LossGain';
import { MdmInput, MdmOutput, MdmMonthlyOutput } from './../services/types';
import { useInputContext } from './../store';
import { useLocation } from 'react-router-dom';
import * as htmlToImage from 'html-to-image';
import axios from "axios";
import { toPounds } from "../services/Formats"
import { extendInput } from "../services/MdmTool"

const ResultsWrapper = styled.div`
    .fw {
        background: #efedf0;
    }
    .cont {
        max-width: 885px;
        margin: 0px auto;

        h2, h3, .results-note, .list {
            color: #666;
            text-align: center;
        }
        h2 {
            font-size: 2em;
        }
        h3 {
            padding-top: 30px;
            font-size: 1.5em;
        }

        .row {
            display: flex;
            flex-wrap: wrap;
        }
        .col {
            flex: 1;
            font-size: 1.25em;
            font-weight: 800;
            color: #666;
            line-height: 2.5em;
        }
        .col.r {
            text-align: right;
        }
        .col.header {
            font-size: 1.66em;
            font-weight: 800;
            margin-bottom: 40px;
        }



        .results-note {
            font-size: 1.2em;
            line-height: 1.5em;
        }
        .list {
            margin-top: 30px;
            margin-bottom: 20px;
        }
        .list .li {
            text-align: left;
            line-height: 1.5em;
            width: 240px;
            margin: 0px auto;
            font-size: 1.33em;
            font-weight: 800;
        }
        p {
            text-align: center;
        }

        .menu {
            display: flex;
            flex-direction:row;
            flex-wrap: wrap;
            padding: 20px;
            max-width: 885px;
            margin: auto;
            margin-bottom: 50px;
        }
        .menu button.button,
        .menu .button,
        .menu .button:visited,
        .menu .button:active {
            font-family: 'Nunito Sans';
            flex: 1;
            margin:20px;
            user-select: none;
            border: none;
            cursor: pointer;

            display: block;
            padding: 20px 40px;
            background-color: #46424a;
            color: white;
            text-decoration: none;
        }
        .menu .button h4 {
            margin-top: 0.33em;
            margin-bottom: 0.33em;
            font-size: 27px;
            font-weight: 600;
            text-align: center;
        }
        .menu .button.disabled {
            pointer-events: none;
            background-color: #86828a;
        }
`;

declare const window: any;

interface Submission {
    input: MdmInput,
    output: MdmOutput;
    snapId: String,
    data: String,
}

export const Results: React.FC<any> = () => {
    const { input, output } = useInputContext();
    const [state, setState] = React.useState<Submission>({ input, output, snapId: '', data:'' })

    const router = useLocation();
    // Get value from query string (?postId=123) or route param (/:postId)
    const multiple = router && router.pathname &&
      router.pathname.indexOf('multiple/') > 0;
    const getMonth = (index: number) => {
        return output.Months
        .filter((m :MdmMonthlyOutput) => (m.Index === index))
    };

    const extendedInput = extendInput(state.input)

    const initialized = document.getElementById("graph") != null;

    React.useMemo(() => {
        if (!initialized) return;
        const snapId = JSON.stringify(input);
        if (state.snapId === snapId) return;
        const elem: HTMLElement = document.getElementById("graph")!;
        htmlToImage.toPng(elem).then((data: String) => {
            console.log('chart snapshot ' + data.length + ' bytes');
            setState({ input, output, snapId, data });
        });

    }, [initialized, input, state.snapId, output]);

    const handleDownloadReport = async () => {
        const username = state?.input?.Submission?.name
        const email = state?.input?.Submission?.email
        const graph = document.getElementById('graph')?.outerHTML
        const pdfVariables = {
            salary: toPounds(state.input.Salary),
            empBenefits: toPounds(extendedInput.EmpBenefits),
            tax: toPounds(extendedInput.Tax),
            totalManSalary: toPounds(extendedInput.TotalManSalary),
            ongManCost: toPounds(extendedInput.OngManCost),
            recrFees: toPounds(extendedInput.RecrFees),
            onboarding: toPounds(state.input.Onboarding),
            deskSpace: toPounds(extendedInput.DeskSpace),
            equipment: toPounds(extendedInput.Equipment),
            total: getMonth(12).map(m => (toPounds(input.NumHire*m.Cost)))[0],
            trainMonth: state.input.TrainMonth,
            trainPerc: state.input.TrainPerc,
            integMonth: state.input.IntegMonth,
            integPerc: state.input.IntegPerc,
            valueMonth: state.input.ValueMonth,
            valuePerc: state.input.ValuePerc,
            maxValuePerc: state.input.MaxValPerc,
            htmlGraph: graph || '',
            lossGain3: `${(getMonth(3)[0].Total > 0) ? 'Gain' : 'Loss'} of ${toPounds(Math.abs(getMonth(3)[0].Total))}`,
            lossGain6: `${(getMonth(6)[0].Total > 0) ? 'Gain' : 'Loss'} of ${toPounds(Math.abs(getMonth(6)[0].Total))}`,
            lossGain12: `${(getMonth(12)[0].Total > 0) ? 'Gain' : 'Loss'} of ${toPounds(Math.abs(getMonth(12)[0].Total))}`,
            lossGain18: `${(getMonth(18)[0].Total > 0) ? 'Gain' : 'Loss'} of ${toPounds(Math.abs(getMonth(18)[0].Total))}`,
            lossGain28: `${(getMonth(28)[0].Total > 0) ? 'Gain' : 'Loss'} of ${toPounds(Math.abs(getMonth(28)[0].Total))}`,
            lossGain36: `${(getMonth(36)[0].Total > 0) ? 'Gain' : 'Loss'} of ${toPounds(Math.abs(getMonth(36)[0].Total))}`
        }

        const url = process.env.NODE_ENV === 'development'
          ? "http://localhost:5080/mail-api/download-report"
          : "https://calculator.mdmconsultants.co.uk/mail-api/download-report"

        try {
            await axios.post(url, { username, email, pdfVariables }).then(res => {
                const data = res?.data
                if (data?.status === 200)
                {
                    const a = document.createElement('a')
                    a.download = 'result.pdf'
                    console.log(window.location.origin + data.file)
                    a.href = window.location.origin + data.file
                    a.click()
                    document.removeChild(a)
                }
            })
        } catch(e) {
            console.log('e', e)
        }
    }

    const handleEmailReport = async () => {
        const username = state?.input?.Submission?.name
        const email = state?.input?.Submission?.email
        const graph = document.getElementById('graph')?.outerHTML
        const pdfVariables = {
            salary: toPounds(state.input.Salary),
            empBenefits: toPounds(extendedInput.EmpBenefits),
            tax: toPounds(extendedInput.Tax),
            totalManSalary: toPounds(extendedInput.TotalManSalary),
            ongManCost: toPounds(extendedInput.OngManCost),
            recrFees: toPounds(extendedInput.RecrFees),
            onboarding: toPounds(state.input.Onboarding),
            deskSpace: toPounds(extendedInput.DeskSpace),
            equipment: toPounds(extendedInput.Equipment),
            total: getMonth(12).map(m => (toPounds(input.NumHire*m.Cost)))[0],
            trainMonth: state.input.TrainMonth,
            trainPerc: state.input.TrainPerc,
            integMonth: state.input.IntegMonth,
            integPerc: state.input.IntegPerc,
            valueMonth: state.input.ValueMonth,
            valuePerc: state.input.ValuePerc,
            maxValuePerc: state.input.MaxValPerc,
            htmlGraph: graph || '',
            lossGain3: `${(getMonth(3)[0].Total > 0) ? 'Gain' : 'Loss'} of ${toPounds(Math.abs(getMonth(3)[0].Total))}`,
            lossGain6: `${(getMonth(6)[0].Total > 0) ? 'Gain' : 'Loss'} of ${toPounds(Math.abs(getMonth(6)[0].Total))}`,
            lossGain12: `${(getMonth(12)[0].Total > 0) ? 'Gain' : 'Loss'} of ${toPounds(Math.abs(getMonth(12)[0].Total))}`,
            lossGain18: `${(getMonth(18)[0].Total > 0) ? 'Gain' : 'Loss'} of ${toPounds(Math.abs(getMonth(18)[0].Total))}`,
            lossGain28: `${(getMonth(28)[0].Total > 0) ? 'Gain' : 'Loss'} of ${toPounds(Math.abs(getMonth(28)[0].Total))}`,
            lossGain36: `${(getMonth(36)[0].Total > 0) ? 'Gain' : 'Loss'} of ${toPounds(Math.abs(getMonth(36)[0].Total))}`
        }

        const url = process.env.NODE_ENV === 'development'
            ? "http://localhost:5080/mail-api/send-download-report"
            : "https://calculator.mdmconsultants.co.uk/mail-api/send-download-report"

        try {
            await axios.post(url, { username, email, pdfVariables });
        } catch(e) {
            console.log('e', e)
        }
    }

    React.useEffect(() => {
        if (!initialized) { setTimeout(() => { setState({ ...state, data: '' }); handleEmailReport(); }, 1000); }
        return () => {};
    });

    return (
      <ResultsWrapper>
          <div className="cont">
              <h2>Results</h2>
              <p>
                  <strong>The below graph shows financial risk and
                      Return on Investment associated with hiring
                      {multiple? ' new employee ' : ' a new employee '}
                      using the figures entered previously.</strong>
              </p>
              <p>
                  Hover over the graph to see total loss or ROI
                  for hire(s) if they leave the business in that month.
              </p>
              <Graph />
              <Legend />
          </div>
          <div className="fw">
              <div className="cont">
                  <div style={{ minHeight: "50px" }}> &nbsp; </div>
                  <div className="row only-desktop">
                      <div className="col l header">
                          Month new
                          {multiple? ' employees quit ' : ' employee quits '}
                      </div>
                      <div className="col r header"
                           style={{ lineHeight: multiple ? 1.2: 2.5 }}>
                          {multiple ? ' Average financial ': ' Financial '}
                          loss/gain {multiple ? <br/> : null}
                          {multiple ? ' per employee ' : null}:
                      </div>
                  </div>

                  <div className="row">
                      <div className="col l">3 months</div>
                      <div className="col r">
                          {getMonth(3).map(
                            v => <LossGain key={3} value={v.Total} />)}
                      </div>
                  </div>
                  <div className="row">
                      <div className="col l">6 months</div>
                      <div className="col r">
                          {getMonth(6).map(
                            v => <LossGain key={6} value={v.Total} />)}
                      </div>
                  </div>
                  <div className="row">
                      <div className="col l">12 months</div>
                      <div className="col r">
                          {getMonth(12).map(
                            v => <LossGain key={12} value={v.Total} />)}
                      </div>
                  </div>
                  <div className="row">
                      <div className="col l">18 months</div>
                      <div className="col r">
                          {getMonth(18).map(
                            v => <LossGain key={18} value={v.Total} />)}
                      </div>
                  </div>
                  <hr />
                  {(output.BreakevenMonth > 0) ? (
                    <div className="row" key={output.BreakevenMonth}>
                        <div className="col l">{output.BreakevenMonth} months</div>
                        <div className="col r">Breakeven</div>
                    </div>
                  ) : null}
                  <div className="row">
                      <div className="col l">36 months</div>
                      <div className="col r">
                          {getMonth(36).map(
                            v => <LossGain key={36} value={v.Total} />)}
                      </div>
                  </div>

                  <h3>Don't forget the hidden costs too</h3>
                  <div className="results-note">
                      The results above provide insight
                      into the costs associated with employment and
                      the length of employment needed to deliver a
                      return on investment. But please consider
                      other incalculable costs of hiring the wrong
                      person, which include:
                  </div>
                  <div className="list">
                      <div className="li">- Business disruption</div>
                      <div className="li">- Dissatisfied clients</div>
                      <div className="li">- Missed opportunities</div>
                      <div className="li">- Lower office morale</div>
                  </div>

                  {/*<form action={'/'} method="POST">*/}
                  {/*    <input type="hidden" name="data" value={encodeURIComponent(JSON.stringify(state))} />*/}
                  <div className="menu">
                      <Link
                        to={multiple ? '/multiple' : '/individual'}
                        className="button"
                      >
                          <h4>Update details</h4>
                      </Link>
                      <button className="button" onClick={handleDownloadReport}>
                          <h4>Download report</h4>
                      </button>
                  </div>
                  {/*</form>*/}
              </div>
          </div>
      </ResultsWrapper>
    );
}
