import React from 'react';
import styled from 'styled-components';

const LegendWrapper = styled.div`
margin: 0px auto;
width: auto;
margin-bottom: 40px;

.items {
    display: flex;
    align-items: center;
    justify-content: center;
}
.item {
    width: 220px;
    display: flex;
    align-items: center;
}
.bullet {
    display: inline-block;
    width: 30px; 
    height: 30px;
    margin-right: 15px;
    padding: 0px;
}
.bullet svg {
    width: 30px;
    height: 30px;
}

.axis-legend {
    text-align:center;
    margin: 20px auto;
    font-weight: 800;
    font-size: 1.1em;
    color: #666;
}
`;

export const Legend: React.FC<any> = () => {
    return (
        <LegendWrapper>
            <div className="items">
                <div className="" style={{ width:200 }}>&nbsp;</div>
                <div className="axis-legend">Month</div>
            </div>

            <div className="items">
                <div className="" style={{ width:200 }}>&nbsp;</div>
                <div className="item roi">
                    <span className="bullet">
                        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 46 L0 54 L90 54 L90 46" fill="#ff7600" stroke="#ff7600" />
                            <circle cx="84" cy="50" r="15" fill="#ff7600"/>
                        </svg>
                    </span>
                    Employee ROI
                </div>
                <div className="item cost">
                    <span className="bullet">
                        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 46 L0 54 L90 54 L90 46" fill="#46424a" stroke="#46424a" />
                            <circle cx="84" cy="50" r="15" fill="#46424a"/>
                        </svg>
                    </span>
                    Employee Cost
                </div>
                <div className="item point">
                    <span className="bullet">
                        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 46 L0 54 L99 54 L99 46" fill="#ff1100" stroke="#ff1100" />
                        </svg>
                    </span>
                    Breakeven Point
                </div>
            </div>
        </LegendWrapper>
    );
}