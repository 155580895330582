import { MdmInput, MdmExtendedInput, MdmOutput, MdmMonthlyOutput } from './types';
import { MdmStorage } from './MdmStorage';

const initialState: MdmInput = {
    NumHire: 1,
    Salary: 100000,
    PercRecrFees: 20, // 20%
    // RecrFees=Salary*PercRecrFees*0.01
    Onboarding: 3000,
    Equipment: 5000,
    PercEmpBenefits: 22,
    // EmpBenefits=Salary*PercEmpBenefits*0.01
    PercTax: 12,
    // Tax=Salary*PercTax*0.01
    TotalManSalary: 70000,
    PercManSalary: 30,
    // ManSalary=TotalManSalary*PercManSalary*0.01
    PercOngManCost: 20,
    // OngManCost=ManSalary*PercOngManCost*0.01
    DDDeskSpace: "M", // or "A"
    DeskSpace: 650,

    TrainMonth: 6,
    TrainPerc: 0,
    IntegMonth: 6,
    IntegPerc: 20,
    ValueMonth: 6,
    ValuePerc: 150,
    MaxValPerc: 200,

    PaybackPerc: 17,
    Submission: {},
};

export const getInitialState = (): MdmInput => {
    return MdmStorage.get(initialState);
}

export const extendInput = (src: MdmInput): MdmExtendedInput => {
    const { Salary, PercRecrFees, PercEmpBenefits } = src;
    const { PercTax, TotalManSalary, PercManSalary, PercOngManCost } = src;
    const RecrFees = Salary * PercRecrFees * 0.01;
    const EmpBenefits = Salary * PercEmpBenefits * 0.01;
    const Tax = Salary * PercTax * 0.01;
    const ManSalary = TotalManSalary * PercManSalary * 0.01;
    const OngManCost = ManSalary * PercOngManCost * 0.01;

    const { TrainMonth, IntegMonth, ValueMonth } = src;
    const MaxValMonth = 36 - TrainMonth - IntegMonth - ValueMonth;
    return { ...src, MaxValMonth, RecrFees, EmpBenefits, Tax, ManSalary, OngManCost };
}

const pad2 = (x: number) => {
    return parseFloat(x.toFixed(2));
}
const positive = (x: number): number => ((x > 0) ? pad2(x) : 0);

export const getOutput = (src: MdmInput): MdmOutput => {
    const input: MdmExtendedInput = extendInput(src);
    const { Salary, DDDeskSpace, DeskSpace, EmpBenefits } = input;
    const { Tax, ManSalary, OngManCost } = input;
    
    const DeskCost: number = (DDDeskSpace === 'A') ? pad2(DeskSpace / 12) : DeskSpace;
    const AvgCost = pad2(Salary/12 + DeskCost +
        EmpBenefits/12 + Tax/12 + ManSalary/12 + OngManCost/12);
    
    const { Equipment, RecrFees, Onboarding } = input;
    const Cost = pad2(AvgCost + Equipment + RecrFees + Onboarding);
    let PrevCost = Cost;
    const Costs: Array<number> = [ Cost ];
    for (let monthIndex = 2; monthIndex <= 36; monthIndex ++) {
        Costs.push(pad2(PrevCost + AvgCost))
        PrevCost = pad2(PrevCost + AvgCost);
    }
    const MonthPayback = pad2((Costs[11] - RecrFees - Onboarding - Equipment)/12);
    const { TrainMonth, IntegMonth, ValueMonth }  = input;
    const { TrainPerc, IntegPerc, ValuePerc }  = input;
    
    let FirstPayback = 0;
    if (Cost !== 0) {
        FirstPayback = 
            ((Cost <= TrainMonth) ? 
                pad2(TrainMonth/12*TrainPerc * 0.01 *MonthPayback) : 0) + 
            ((Cost>TrainMonth && Cost<=(TrainMonth+IntegMonth)) ? 
                pad2(IntegMonth/12*IntegPerc * 0.01 *MonthPayback) : 0) + 
            ((Cost>(TrainMonth+IntegMonth) && Cost <=(TrainMonth+IntegMonth+ValueMonth)) ? 
                pad2(ValueMonth/12*ValuePerc * 0.01 *MonthPayback) : 0)
    }

    const  { MaxValPerc, MaxValMonth } = input;
    let PrevPayback = FirstPayback;
    const Months: Array<MdmMonthlyOutput> = [ { 
        Index: 1, Cost, Payback: FirstPayback, Total: pad2(-Cost + FirstPayback),
    }];
    for (let Index = 2; Index <= 36; Index ++) {
        const Cost = Costs[Index-1];
        let Payback = 0;
        if (Cost !== 0 || Index < MaxValMonth + 6) {
            Payback = positive(
                PrevPayback + 
                ((Index <= TrainMonth) ? 
                    pad2(TrainPerc * 0.01 * MonthPayback) : 0) +
                ((Index > TrainMonth) && (Index <= (TrainMonth + IntegMonth)) ? 
                    pad2(IntegPerc * 0.01 * MonthPayback) : 0) +
                (((Index > (TrainMonth + IntegMonth)) && (Index <= (TrainMonth + IntegMonth + ValueMonth))) ?
                    pad2(ValuePerc * 0.01 * MonthPayback) : 0) +
                (((Index > (TrainMonth + IntegMonth + ValueMonth) && Index <= (TrainMonth + IntegMonth + ValueMonth + MaxValMonth))) ?
                    pad2(MaxValPerc * 0.01 * MonthPayback) : 0)
            )
        }
        Months.push({ Index, Cost, Payback, Total: pad2(-Cost + Payback) });
        PrevPayback = Payback;
    }

    const BreakevenMonth = Months
            .filter((m :MdmMonthlyOutput) => (m.Total > 0))
            .filter((item: any, idx: number) => (idx === 0))[0];
   
    return { AvgCost, Months, BreakevenMonth: BreakevenMonth?.Index };
}

export const Reducer = (state: any, action: any) => {
    console.log('Reducer', 'state=', state, 'action=', action);
    switch (action.type) {
        case 'SET_INPUT':
            const newState = { ...state, ...action.payload };
            MdmStorage.set(action.payload);
            return newState;
        default:
            return state;
    }
};

