import React from 'react';
import styled from 'styled-components';
import { useHistory } from "react-router-dom";
import { useInputContext } from './../store';
import Checkbox from './../components/Elements/Checkbox';

const SubmissionWrapper = styled.div`
    max-width: 950px;
    margin: 0px auto;
    h2 { 
        color: #46424a !important;
        text-align: center;
        font-size: 2em;
    }
    .note {
        font-size: 0.8em;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 40px;
    }
    .check {
        font-size: 0.8em;
        min-height: 36px;
        
        padding-left: 30px;
        padding-right: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .box { 
        display: inline-block;
        min-width:24px;
        min-height:24px; 
        max-width:24px;
        max-height:24px; 
        margin-right: 30px; 
        border: 1px #9c9a9f solid;
        cursor: pointer;
    }
    input[type=text],
    input[type=email] {
        text-align: center;
        border: none;
        font-weight: 800;
        font-size: 1.66em;
        color: #46424a;
        padding: 12px 5px;
        border-bottom: 1px #46424a solid;
    }
    .button,
    .button:visited,
    .button:active {
        flex: 1;
        margin: 30px auto; 
        user-select: none;
        max-width: 240px;

        cursor: pointer;
        border: none;

        display: block;
        padding: 20px 40px;
        background-color: #46424a;
        color: white;
        text-decoration: none;
    
        font-size: 1.75em;
        font-weight: 800;
        text-align: center;
    }
    .button.disabled {
        background-color: #86828a;
    }

    .columns {
        margin: auto;
        margin-top: 30px;
        margin-bottom: 50px;

        display: flex;
        flex-direction:row;
        flex-wrap: wrap;
        max-width: 885px;
    }
    .column {
        font-size: 0.75em;
        line-height: 1.5em;
        flex: 1;
        text-align: center;
    }

`;

export const submitToCRM = (payload: any): Promise<any> => {
    const url = process.env.REACT_APP_LEADS_URL || '';
    if (!url) {
        return Promise.resolve({});
    }
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    const req =  new Request(url, { 
        method: 'POST',
        mode: 'cors',
        redirect: 'follow',
        headers,
        body: JSON.stringify(payload)
    });
    return fetch(req).then(r => (r.text()));
};

export const Submission: React.FC<any> = (props: any) => { 
    const { input, onChange } = useInputContext();
    const { Submission } = input;
    const [name, setName] = React.useState(Submission ? Submission.name : '');
    const [company, setCompany] = React.useState(Submission ? Submission.company : '');
    const [email, setEmail] = React.useState(Submission ? Submission.email: '');
    const [check1, setCheck1] = React.useState(false);
    const toggle1 = () => (setCheck1(!check1));
    const [check2, setCheck2] = React.useState(false);
    const toggle2 = () => (setCheck2(!check2));

    const { multiple } = props;
    const nextUrl = (multiple ? '/multiple' : '/individual') + '/results';
    const payload = {
        url: process.env.REACT_APP_LEADS_URL,
        details: "", name, email, company,
    };
    const canSubmit = !!name && !!email && !!company && check1 && check2;
    let history = useHistory();
    return ( 
        <SubmissionWrapper>
            <h2>To see the full ROI results please enter your:</h2>
            <div className="columns">
                <div className="column">
                    <input 
                        type="text"
                        placeholder="Name"
                        defaultValue={name}
                        onChange={e => { setName(e.target.value) }}
                    />
                </div>
                <div className="column">
                    <input
                        type="text"
                        placeholder="Company"
                        defaultValue={company}
                        onChange={e => { setCompany(e.target.value) }}
                    />
                </div>
                <div className="column">
                    <input
                        type="email"
                        placeholder="Email"
                        defaultValue={email}
                        onChange={e => { setEmail(e.target.value) }}
                    />
                </div>
            </div>
            <div className="note">
                By clicking submit, a notification is automatically generated 
                and sent to MDM Consultants who will be on hand to discuss
                the results in more detail if you wish.
            </div>
            <div className="check">
                <Checkbox
                    value={check1}
                    onChange={toggle1}
                    title="I would like to receive a follow up email from MDM Consultants
                        to discuss the results in more detail."
                />
            </div>
            <div className="check">
                <Checkbox 
                    value={check2} 
                    onChange={toggle2}
                    title="I agree to the terms and conditions of the MDM consultant's privacy policy*" 
                />
            </div>
            <button 
                disabled={!canSubmit}
                className={"button " + (canSubmit ? "active": "disabled")} 
                onClick={()=> {
                    onChange('Submission')(payload);
                    submitToCRM(payload).then(() => {
                        history.push(nextUrl);
                    }).catch((e: any) => {
                        console.error(e);
                        history.push(nextUrl);
                    });
                }}
            >
                Submit
            </button>

        </SubmissionWrapper>
    );
} 
