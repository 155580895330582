import { MdmInput } from './types';
import { Debounced } from './Debounced';

const key = "MDM_v20210702";
let inMem: MdmInput | null = null;

const saved = (x: MdmInput): MdmInput => {
    inMem = x;
    return x;
};

export const MdmStorage = {
    get: (defaultValue: MdmInput): MdmInput => {
        if (inMem !== null) {
            // console.log("from memory", inMem.Equipment, inMem.DeskSpace);
            return inMem;
        }
        const strVal = sessionStorage.getItem(key);
        if (!strVal || strVal === "null") {
            // console.log("defaultValue");
            return defaultValue;
        }
        try {
            // const { Equipment, DeskSpace } = JSON.parse(strVal);
            // console.log("from session", Equipment, DeskSpace);
            return JSON.parse(strVal);
        } catch {
        }
        // console.log("defaultValue");
        return defaultValue;
    },
    set: (val: MdmInput) => {
        const savedInMem = saved(val);
        Debounced.start('key', () => {
             sessionStorage.setItem(key, JSON.stringify(savedInMem))
        });
    }
};