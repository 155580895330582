import React from "react";
import { getInitialState, extendInput, getOutput } from './services/MdmTool';
import { MdmStorage } from './services/MdmStorage';
import { MdmFullContextType, MdmInputContainer } from './services/types'

// export interface MdmContextType {
//     state: MdmInputContainer
//     dispatch: React.Dispatch<any>
// }

const initialState = {
     input: getInitialState()
};

// export const Store = (props: any) => { 
//     const [state, dispatch] = useReducer(Reducer, initialState);
//     return (
//         <Store value={{state, dispatch}}>
//             {props.children}
//         </Store>
//     );
// };

// export const Context = createContext<MdmContextType>({ 
//     state: initialState,
//     dispatch: (action: any) => {
//         const [oldState, d] = useState(Reducer, initialState);
//         console.warn('Context.dispatch', action);
//     }
// });


/*{
    state, 
    dispatch: (action: any) => {
        console.warn('Context.dispatch', action);
    }
});
*/


export interface MdmContextType {
    state: MdmInputContainer
}

export const useInputContext = (): MdmFullContextType => {
    const [state, setState] = React.useState(initialState)
    const input = MdmStorage.get(state.input);
    const ext = extendInput(input);
    const output = getOutput(input);
    const onChange = (field: string) => {
        return (val: any) => {
            // console.log('Entry', {...input, [field]: val});
            // dispatch({ type: 'SET_INPUT', payload: {...input, [field]: val} }); 
            setState({ input: {...state.input, [field]: val}});
            MdmStorage.set({...state.input, [field]: val});
        };
    }
    return { input, ext, output, onChange };
};