import React from 'react';
import './App.css';

import ScrollToTop from './ScrollToTop';
import { HashRouter, Switch, Route } from 'react-router-dom'; // withRouter
import { Header, Footer } from './../components/Layout';
import { Intro, Individual, Multiple } from './../components/Pages';
import { Results } from './../components/Results';

const isIFrame = window.top !== window.self;
const style = isIFrame ? {} : { minHeight: '100vh' };

function App(props: any) {
  return (
    <div className="App container" style={style}>
      <HashRouter>
        <ScrollToTop />
        {/*<Header />*/}
        <Switch>
          <Route exact path='/' component={Intro} />
          <Route exact path='/individual' component={Individual} />
          <Route exact path='/multiple' component={Multiple} />
          <Route exact path='/individual/results' component={Results} />
          <Route exact path='/multiple/results' component={Results} />
        </Switch>
      </HashRouter>
      {/*<Footer />*/}
    </div>
  );
}

export default App;
