import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
// import Debounced from './services/Debounced';
// import { triggerResize } from './services/screen';
// import { Context } from './store';

if (document.getElementById('root')) {
  // resetLocalStorage();
  const Root = () => (<App />);
  ReactDOM.render(<Root />, document.getElementById('root'));
  // window.addEventListener('resize', () => { Debounced.start('resize', notifyResize, 250); });
  // triggerResize('index');
} else {
  console.error('nothing to render, root not found');
}
  
